<template>
  <div>
    <v-container>
       <v-toolbar elevation="0" class="pa-2" color="transparent" max-height="20">
        <v-img src="../../assets/img/nzeta-logo.png" class="logo-style" max-width="160"></v-img>
        <v-spacer></v-spacer>
        <Translation />
       </v-toolbar>
    </v-container>
  
  <div class="content">
    <!-- <div>
      <v-alert
      text
      class="payment-alert"
    >
      <div class="text-center">
        Payment required for ESTA Application Ref. #{{$store.state.token}} — <span v-if="$store.state.formdata && $store.state.formdata.applicant" class="text-uppercase">{{$store.state.formdata.applicant.family_names}}, {{$store.state.formdata.applicant.first_given_names}}</span>
      </div>
    </v-alert>
    </div> -->

        <section class="section">
    <div v-if="payment_data && payment_data.payment_page_type == 'simple'" class="container simple-style">
        <v-row>
          <v-col cols="12" align="center">
            <h3 class="mb-5 font-weight-regular" style="color:#C5C6C7; font-size: 16px;" v-if="payment_data && payment_data.pricing">Pay Your {{parseFloat(payment_data.pricing.base_price) + parseFloat(express ? payment_data.pricing.express_add_on : 0)}} USD NZ-ETA Processing Fee</h3>
            <v-card
              outlined
              class="mt-5 elevation-0"
              max-width="800"
            >
            <v-card-text class="pa-0">
              <v-expansion-panels v-model="payment_method" focusable accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate color="#00a34e" :class="{ unclickable: payment_method == 0 }">
                    <h3 :style="{ lineHeight: '1.3', color: 'white' }">Pay with Credit or Debit Card</h3>
                    <template v-slot:actions>
                      <v-row justify="end" no-gutters>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/visa_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/mastercard_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/amex_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/unionpay_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/jcb_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/discover_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/dinersclub_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="stripe_data.payment_details" class="py-5">
                        <stripe-element-payment
                        ref="paymentRef"
                        :pk="public_key"
                        :elements-options="elementsOptions"
                        :confirm-params="confirmParams"
                        @error="handleError"
                      />
                      <v-alert class="mt-3 text-left" type="error" dense v-if="payment_error_msg">
                        {{payment_error_msg}}
                      </v-alert>
                      <v-btn v-if="fieldsReady" color="#00A34E" large dark block depressed @click="pay" :loading="loadingPay" class="mt-6">Pay Now</v-btn>
                      <v-row justify="center" class="mt-5" no-gutters>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure1.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure2.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure3.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure4.png" width="100" class="mx-2"></v-img></v-col>
                      </v-row>
                  </div>
                  <div class="text-center py-5" v-else>
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </div>
    
    <div v-else-if="payment_data && payment_data.payment_page_type == 'simple_plus_local'" class="container">

      <v-row>
          <v-col cols="12" align="center">
            <h3 class="mb-5 font-weight-regular" style="color:#C5C6C7; font-size: 16px;" v-if="payment_data && payment_data.pricing">Pay Your {{parseFloat(payment_data.pricing.base_price) + parseFloat(express ? payment_data.pricing.express_add_on : 0)}} USD NZ-ETA Processing Fee</h3>
                  <v-alert max-width="800" class="text-left" type="error" dense v-if="revolut_error">
                    {{revolut_error}}
                  </v-alert>
            <v-card
              outlined
              class="mt-5"
              max-width="800"
            >
            <v-card-title class="bc-style">
              <v-row>
                <v-col cols="12" sm="7" justify="center" :align="currentSize >= 2 ? 'left' : 'center'">
                  <h5 class="mt-3 text-no-wrap" :style="{ lineHeight: '1' }">Choose Your Billing Country</h5>
                </v-col>
                <v-col cols="12" sm="5" :align="currentSize >= 2 ? 'right' : 'center'">
                  <v-autocomplete
                  v-model="billing_country"
                  outlined
                  dense
                  hide-details
                  color="lightgrey"
                  :items="billing_countries"
                  item-value="value"
                  item-text="text"
                  style="max-width: 300px; background-color: white"
                  class="notranslate"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-expansion-panels v-model="payment_method" focusable accordion>
                <v-expansion-panel v-show="checkPaymentAvailability('tazapay') && allow_tazapay">
                  <v-expansion-panel-header disable-icon-rotate :class="{ unclickable: payment_method == 0 }">
                    <v-radio-group v-model="selectedOption" class="ma-0 mb-1" hide-details>
                      <v-radio color="#0771b7" value="option1"></v-radio>
                    </v-radio-group>
                    <h3 :style="{ lineHeight: '1.3' }">Pay with Local Payment Method</h3>
                    <template v-slot:actions>
                      <v-row justify="end" no-gutters>
                      <v-col cols="auto" v-for="lm in local_methods" :key="lm"><img :src="require('../../assets/img/payment/payment-methods/' + (is_eu ? 'local_icons/' + billing_country + '/' : '') + lm)" class="ma-1 lm-style"></v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-btn color="#00A34E" large dark block depressed @click="tazapay()" :loading="loadingTazapay" class="mt-5">Continue to payment >></v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-show="checkPaymentAvailability('revolut')">
                  <v-expansion-panel-header disable-icon-rotate :class="{ unclickable: payment_method == 1 }">
                    <v-radio-group v-model="selectedOption" class="ma-0 mb-1" hide-details>
                      <v-radio color="#0771b7" value="option2"></v-radio>
                    </v-radio-group>
                    <h3 :style="{ lineHeight: '1.3' }">Pay with Visa or Mastercard</h3>
                    <template v-slot:actions>
                      <v-row justify="end" no-gutters>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/visa_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/mastercard_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-btn color="#00A34E" large dark block depressed @click="revolut()" :loading="loadingRevolut" class="my-5">Continue to payment >></v-btn>
                    <v-row justify="center" class="mt-5" no-gutters>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure1.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure2.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure3.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure4.png" width="100" class="mx-2"></v-img></v-col>
                      </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-show="checkPaymentAvailability('stripe')">
                  <v-expansion-panel-header disable-icon-rotate :class="{ unclickable: payment_method == 2 }">
                    <v-radio-group v-model="selectedOption" class="ma-0 mb-1" hide-details>
                      <v-radio color="#0771b7" value="option3"></v-radio>
                    </v-radio-group>
                    <h3 :style="{ lineHeight: '1.3' }">{{ checkPaymentAvailability('stripe') && checkPaymentAvailability('revolut') ? 'Pay with Other Creadit or Debit Card' : 'Pay with Credit or Debit Card' }}</h3>
                    <template v-slot:actions>
                      <v-row justify="end" no-gutters v-if="checkPaymentAvailability('stripe') && checkPaymentAvailability('revolut')">
                      <v-col cols="auto"><v-img src="../../assets/img/payment/amex_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/unionpay_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/jcb_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/discover_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/dinersclub_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      </v-row>
                      <v-row justify="end" no-gutters v-else>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/visa_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/mastercard_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/amex_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/unionpay_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/jcb_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/discover_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/dinersclub_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="stripe_data.payment_details" class="py-5">
                        <stripe-element-payment
                        ref="paymentRef"
                        :pk="public_key"
                        :elements-options="elementsOptions"
                        :confirm-params="confirmParams"
                        @error="handleError"
                      />
                      <v-alert class="mt-3 text-left" type="error" dense v-if="payment_error_msg">
                        {{payment_error_msg}}
                      </v-alert>
                      <v-btn v-if="fieldsReady" color="#00A34E" large dark block depressed @click="pay" :loading="loadingPay" class="mt-6">Pay Now</v-btn>
                      <v-row justify="center" class="mt-5" no-gutters>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure1.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure2.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure3.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure4.png" width="100" class="mx-2"></v-img></v-col>
                      </v-row>
                  </div>
                  <div class="text-center py-5" v-else>
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-show="checkPaymentAvailability('bmo')">
                  <v-expansion-panel-header disable-icon-rotate :class="{ unclickable: payment_method == 3 }">
                    <v-radio-group v-model="selectedOption" class="ma-0 mb-1" hide-details>
                      <v-radio color="#0771b7" value="option4"></v-radio>
                    </v-radio-group>
                    <h3 :style="{ lineHeight: '1.3' }">Pay with Credit or Debit Card</h3>
                    <template v-slot:actions>
                      <v-row justify="end" no-gutters>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/visa_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/mastercard_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/dinersclub_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-btn color="#00A34E" large dark block depressed @click="bmo()" :loading="loadingBmo" class="my-5">Continue to payment >></v-btn>
                    <v-alert class="text-left" type="error" dense v-if="bmo_error">
                        {{bmo_error}}
                      </v-alert>
                    <v-row justify="center" class="mt-5" no-gutters>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure1.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure2.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure3.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure4.png" width="100" class="mx-2"></v-img></v-col>
                      </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-show="checkPaymentAvailability('bmo')">
                  <v-expansion-panel-header disable-icon-rotate :class="{ unclickable: payment_method == 4 }">
                    <v-radio-group v-model="selectedOption" class="ma-0 mb-1" hide-details>
                      <v-radio color="#0771b7" value="option5"></v-radio>
                    </v-radio-group>
                    <h3 :style="{ lineHeight: '1.3' }">Pay with Credit or Debit Card</h3>
                    <template v-slot:actions>
                      <v-row justify="end" no-gutters>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/amex_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/jcb_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-btn color="#00A34E" large dark block depressed @click="bmo()" :loading="loadingBmo" class="my-5">Continue to payment >></v-btn>
                    <v-alert class="text-left" type="error" dense v-if="bmo_error">
                        {{bmo_error}}
                      </v-alert>
                    <v-row justify="center" class="mt-5" no-gutters>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure1.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure2.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure3.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure4.png" width="100" class="mx-2"></v-img></v-col>
                      </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </div>

    <div v-else class="container">
        <v-row>
          <v-col cols="12" md="7">
            <!-- <v-alert
              text
              color="#28a745"
              border="left"
            >
              Secure Form
            </v-alert> -->
            <v-card
              outlined
            >
            <v-card-title class="pa-0 so-style flex-items">
                <v-icon class="mr-2" color="#fff">mdi-web-box</v-icon>
                <h5>SERVICE OPTIONS</h5>
            </v-card-title>
            <v-card-text>
              <v-card elevation="0" class="my-4 pa-5 payment-service-type">
                <v-row no-gutters>
                  <v-col cols="12"><span class="text-overline">Service Type</span></v-col>
                  <v-col cols="8"><span class="text-h6 font-weight-bold">NZ-ETA Processing Service</span></v-col>
                  <v-col cols="4" align="end"><span class="text-h6" v-if="payment_data.pricing">{{formatMoney(payment_data.pricing.base_price, payment_data.pricing.base_currency)}}</span></v-col>
                </v-row>
              </v-card>
              <!-- <div class="my-3 service-details">
                Our ESTA full service includes a detailed review of your application, a check for any inconsistencies, and the creation of any additional accounts if needed. Government fees are included.
              </div> -->
              <!-- <v-divider></v-divider> -->
              <div class="payment-add-ons">
                <div class="text-h6 my-0">Select Add-ons</div>
                <v-row no-gutters>
                  <v-col cols="2" sm="1" align="center" justify="center" class="text-center mt-n3"><v-checkbox class="d-inline-flex" color="#000" v-model="express"></v-checkbox></v-col>
                  <v-col cols="8" sm="9" justify="center">
                    <strong>Express Service</strong><br />
                    <span>within 3 hours.</span>
                  </v-col>
                  <v-col cols="2" justify="end" align="end">
                    <span class="text-subtitle1 font-weight-bold" v-if="payment_data.pricing">{{formatMoney(payment_data.pricing.express_add_on, payment_data.pricing.base_currency)}}</span>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            </v-card>

            <v-card
              outlined
              class="mt-5"
            >
            <v-card-title class="bc-style">
              <v-row>
                <v-col cols="12" sm="7" justify="center" :align="currentSize >= 2 ? 'left' : 'center'">
                  <h5 class="mt-3 text-no-wrap" :style="{ lineHeight: '1' }">Choose Your Billing Country</h5>
                </v-col>
                <v-col cols="12" sm="5" :align="currentSize >= 2 ? 'right' : 'center'">
                  <v-autocomplete
                  v-model="billing_country"
                  outlined
                  hide-details
                  color="lightgrey"
                  :items="billing_countries"
                  item-value="value"
                  item-text="text"
                  style="max-width: 300px; background-color: white"
                  class="notranslate"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-expansion-panels v-model="payment_method" focusable accordion>
                <v-expansion-panel v-show="allow_tazapay">
                  <v-expansion-panel-header disable-icon-rotate :class="{ unclickable: payment_method == 0 }">
                    <v-radio-group v-model="selectedOption" class="ma-0 mb-1" hide-details>
                      <v-radio color="#0771b7" value="option1"></v-radio>
                    </v-radio-group>
                    <h3 :style="{ lineHeight: '1.3' }">Pay with Local Payment Method</h3>
                    <template v-slot:actions>
                      <v-row justify="end" no-gutters>
                      <v-col cols="auto" v-for="lm in local_methods" :key="lm"><img :src="require('../../assets/img/payment/payment-methods/' + (is_eu ? 'local_icons/' + billing_country + '/' : '') + lm)" class="ma-1 lm-style"></v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-btn color="#00A34E" large dark block depressed @click="tazapay()" :loading="loadingTazapay" class="mt-5">Continue to payment >></v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate  :class="{ unclickable: payment_method == 1 }">
                    <v-radio-group v-model="selectedOption" class="ma-0 mb-1" hide-details>
                      <v-radio color="#0771b7" value="option2"></v-radio>
                    </v-radio-group>
                    <h3 :style="{ lineHeight: '1.3' }">Pay with Credit or Debit Card</h3>
                    <template v-slot:actions>
                      <v-row justify="end" no-gutters>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/visa_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/mastercard_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/amex_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/unionpay_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/jcb_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/discover_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      <v-col cols="auto"><v-img src="../../assets/img/payment/dinersclub_logo.svg" class="ma-1 cc-style"></v-img></v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="stripe_data.payment_details" class="py-5">
                        <stripe-element-payment
                        ref="paymentRef"
                        :pk="public_key"
                        :elements-options="elementsOptions"
                        :confirm-params="confirmParams"
                        @error="handleError"
                      />
                      <v-alert class="mt-3 text-left" type="error" dense v-if="payment_error_msg">
                        {{payment_error_msg}}
                      </v-alert>
                      <v-btn v-if="fieldsReady" color="#00A34E" large dark block depressed @click="pay" :loading="loadingPay" class="mt-6">Pay Now</v-btn>
                      <v-row justify="center" class="mt-5" no-gutters>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure1.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure2.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure3.png" width="100" class="mx-2"></v-img></v-col>
                        <v-col align="center" cols="3"><v-img src="../../assets/img/payment/secure4.png" width="100" class="mx-2"></v-img></v-col>
                      </v-row>
                  </div>
                  <div class="text-center py-5" v-else>
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              
            </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="5">
            <v-card
              outlined
            >
            <v-card-text>
              <div class="text-center my-2 text-h5 font-weight-bold">Order Summary</div>
              <v-divider></v-divider>
              <div class="mt-3 payment-desc">
                <v-row class="py-0" no-gutters>
                  <v-col class="font-weight-bold">NZ-ETA Processing Service</v-col>
                  <v-col class="ml-auto font-weight-bold" align="end"><span v-if="payment_data.pricing">{{formatMoney(payment_data.pricing.base_price, payment_data.pricing.base_currency)}}</span></v-col>
                </v-row>
                <v-row class="py-0 express-style mt-2" no-gutters v-if="express ">
                  <v-col class="flex-items"><v-icon color="#00A34E">mdi-check-bold</v-icon><strong>Express Service</strong></v-col>
                  <v-col class="ml-auto" align="end"><strong v-if="payment_data.pricing">{{formatMoney(payment_data.pricing.express_add_on, payment_data.pricing.base_currency)}}</strong></v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row color="green" class="pt-1">
                  <v-col><h3><strong>Order Total</strong></h3></v-col>
                  <v-col class="ml-auto" align="end"><h3><strong v-if="payment_data.pricing">{{formatMoney(parseFloat(payment_data.pricing.base_price) + parseFloat(express ? payment_data.pricing.express_add_on : 0), payment_data.pricing.base_currency)}}</strong></h3></v-col>
                </v-row>
              </div>
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </div>
</section>
      </div>
  <Footer />
  </div>
  
</template>

<script>
import Translation from '../../components/Utils/Translation.vue';
import Footer from '../../components/Footer/Footer.vue';
import { StripeElementPayment  } from '@vue-stripe/vue-stripe';
export default {
  name: "PaymentThankYou",
  components: {
    Translation,
    Footer,
    StripeElementPayment,
  },
  data: () => ({
    payment_data: {},
    public_key: '',
    elementsOptions: {
      appearance: {}, // appearance options
    },
    confirmParams: {
      return_url: '', // success url
    },
    stripe_data: {},
    payment_method: null,
    express: false,
    billing_country: null,
    billing_countries: [
    {"value": "AF", "data-eu": "N", "data-taza": "N", "data-select2-id": "251", "text": "AFGHANISTAN"},
    {"value": "AL", "data-eu": "N", "data-taza": "N", "data-select2-id": "256", "text": "ALBANIA"},
    {"value": "DZ", "data-eu": "N", "data-taza": "N", "data-select2-id": "257", "text": "ALGERIA"},
    {"value": "AS", "data-eu": "N", "data-taza": "N", "data-select2-id": "258", "text": "AMERICAN SAMOA"},
    {"value": "AD", "data-eu": "N", "data-taza": "N", "data-select2-id": "259", "text": "ANDORRA"},
    {"value": "AO", "data-eu": "N", "data-taza": "N", "data-select2-id": "260", "text": "ANGOLA"},
    {"value": "AI", "data-eu": "N", "data-taza": "N", "data-select2-id": "261", "text": "ANGUILLA"},
    {"value": "AQ", "data-eu": "N", "data-taza": "N", "data-select2-id": "262", "text": "ANTARCTICA"},
    {"value": "AG", "data-eu": "N", "data-taza": "N", "data-select2-id": "263", "text": "ANTIGUA AND BARBUDA"},
    {"value": "AR", "data-eu": "N", "data-taza": "N", "data-select2-id": "264", "text": "ARGENTINA"},
    {"value": "AM", "data-eu": "N", "data-taza": "N", "data-select2-id": "265", "text": "ARMENIA"},
    {"value": "AW", "data-eu": "N", "data-taza": "N", "data-select2-id": "266", "text": "ARUBA"},
    {"value": "AU", "data-eu": "N", "data-taza": "N", "data-select2-id": "267", "text": "AUSTRALIA"},
    {"value": "AT", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "268", "text": "AUSTRIA"},
    {"value": "AZ", "data-eu": "N", "data-taza": "N", "data-select2-id": "269", "text": "AZERBAIJAN"},
    {"value": "BS", "data-eu": "N", "data-taza": "N", "data-select2-id": "270", "text": "BAHAMAS"},
    {"value": "BH", "data-eu": "N", "data-taza": "N", "data-select2-id": "271", "text": "BAHRAIN"},
    {"value": "BD", "data-eu": "N", "data-taza": "N", "data-select2-id": "272", "text": "BANGLADESH"},
    {"value": "BB", "data-eu": "N", "data-taza": "N", "data-select2-id": "273", "text": "BARBADOS"},
    {"value": "BY", "data-eu": "N", "data-taza": "N", "data-select2-id": "274", "text": "BELARUS"},
    {"value": "BE", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "275", "text": "BELGIUM"},
    {"value": "BZ", "data-eu": "N", "data-taza": "N", "data-select2-id": "276", "text": "BELIZE"},
    {"value": "BJ", "data-eu": "N", "data-taza": "N", "data-select2-id": "277", "text": "BENIN"},
    {"value": "BM", "data-eu": "N", "data-taza": "N", "data-select2-id": "278", "text": "BERMUDA"},
    {"value": "BT", "data-eu": "N", "data-taza": "N", "data-select2-id": "279", "text": "BHUTAN"},
    {"value": "BO", "data-eu": "N", "data-taza": "N", "data-select2-id": "280", "text": "BOLIVIA"},
    {"value": "BA", "data-eu": "N", "data-taza": "N", "data-select2-id": "281", "text": "BOSNIA AND HERZEGOVINA"},
    {"value": "BW", "data-eu": "N", "data-taza": "N", "data-select2-id": "282", "text": "BOTSWANA"},
    {"value": "BV", "data-eu": "N", "data-taza": "N", "data-select2-id": "283", "text": "BOUVET ISLAND"},
    {"value": "BR", "data-eu": "N", "data-taza": "N", "data-select2-id": "284", "text": "BRAZIL"},
    {"value": "IO", "data-eu": "N", "data-taza": "N", "data-select2-id": "285", "text": "BRITISH INDIAN OCEAN TERRITORY"},
    {"value": "BN", "data-eu": "N", "data-taza": "N", "data-select2-id": "286", "text": "BRUNEI DARUSSALAM"},
    {"value": "BG", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "287", "text": "BULGARIA"},
    {"value": "BF", "data-eu": "N", "data-taza": "N", "data-select2-id": "288", "text": "BURKINA FASO"},
    {"value": "BI", "data-eu": "N", "data-taza": "N", "data-select2-id": "289", "text": "BURUNDI"},
    {"value": "CV", "data-eu": "N", "data-taza": "N", "data-select2-id": "290", "text": "CABO VERDE"},
    {"value": "KH", "data-eu": "N", "data-taza": "N", "data-select2-id": "291", "text": "CAMBODIA"},
    {"value": "CM", "data-eu": "N", "data-taza": "N", "data-select2-id": "292", "text": "CAMEROON"},
    {"value": "CA", "data-eu": "N", "data-taza": "N", "data-select2-id": "293", "text": "CANADA"},
    {"value": "KY", "data-eu": "N", "data-taza": "N", "data-select2-id": "294", "text": "CAYMAN ISLANDS"},
    {"value": "CF", "data-eu": "N", "data-taza": "N", "data-select2-id": "295", "text": "CENTRAL AFRICAN REPUBLIC"},
    {"value": "TD", "data-eu": "N", "data-taza": "N", "data-select2-id": "296", "text": "CHAD"},
    {"value": "CL", "data-eu": "N", "data-taza": "N", "data-select2-id": "297", "text": "CHILE"},
    {"value": "CN", "data-eu": "N", "data-taza": "N", "data-select2-id": "298", "text": "CHINA"},
    {"value": "CX", "data-eu": "N", "data-taza": "N", "data-select2-id": "299", "text": "CHRISTMAS ISLAND"},
    {"value": "CC", "data-eu": "N", "data-taza": "N", "data-select2-id": "300", "text": "COCOS (KEELING) ISLANDS"},
    {"value": "CO", "data-eu": "N", "data-taza": "Y", "data-select2-id": "301", "text": "COLOMBIA"},
    {"value": "KM", "data-eu": "N", "data-taza": "N", "data-select2-id": "302", "text": "COMOROS"},
    {"value": "CG", "data-eu": "N", "data-taza": "N", "data-select2-id": "303", "text": "CONGO"},
    {"value": "CD", "data-eu": "N", "data-taza": "N", "data-select2-id": "304", "text": "CONGO, DEMOCRATIC REPUBLIC OF THE"},
    {"value": "CK", "data-eu": "N", "data-taza": "N", "data-select2-id": "305", "text": "COOK ISLANDS"},
    {"value": "CR", "data-eu": "N", "data-taza": "N", "data-select2-id": "306", "text": "COSTA RICA"},
    {"value": "CI", "data-eu": "N", "data-taza": "N", "data-select2-id": "307", "text": "CÔTE D'IVOIRE"},
    {"value": "HR", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "308", "text": "CROATIA"},
    {"value": "CU", "data-eu": "N", "data-taza": "N", "data-select2-id": "309", "text": "CUBA"},
    {"value": "CW", "data-eu": "N", "data-taza": "N", "data-select2-id": "310", "text": "CURAÇAO"},
    {"value": "CY", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "311", "text": "CYPRUS"},
    {"value": "CZ", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "312", "text": "CZECH REPUBLIC"},
    {"value": "DK", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "313", "text": "DENMARK"},
    {"value": "DJ", "data-eu": "N", "data-taza": "N", "data-select2-id": "314", "text": "DJIBOUTI"},
    {"value": "DM", "data-eu": "N", "data-taza": "N", "data-select2-id": "315", "text": "DOMINICA"},
    {"value": "DO", "data-eu": "N", "data-taza": "N", "data-select2-id": "316", "text": "DOMINICAN REPUBLIC"},
    {"value": "EC", "data-eu": "N", "data-taza": "N", "data-select2-id": "317", "text": "ECUADOR"},
    {"value": "EG", "data-eu": "N", "data-taza": "N", "data-select2-id": "318", "text": "EGYPT"},
    {"value": "SV", "data-eu": "N", "data-taza": "N", "data-select2-id": "319", "text": "EL SALVADOR"},
    {"value": "GQ", "data-eu": "N", "data-taza": "N", "data-select2-id": "320", "text": "EQUATORIAL GUINEA"},
    {"value": "ER", "data-eu": "N", "data-taza": "N", "data-select2-id": "321", "text": "ERITREA"},
    {"value": "EE", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "322", "text": "ESTONIA"},
    {"value": "SZ", "data-eu": "N", "data-taza": "N", "data-select2-id": "323", "text": "ESWATINI"},
    {"value": "ET", "data-eu": "N", "data-taza": "N", "data-select2-id": "324", "text": "ETHIOPIA"},
    {"value": "FK", "data-eu": "N", "data-taza": "N", "data-select2-id": "325", "text": "FALKLAND ISLANDS (MALVINAS)"},
    {"value": "FO", "data-eu": "N", "data-taza": "N", "data-select2-id": "326", "text": "FAROE ISLANDS"},
    {"value": "FJ", "data-eu": "N", "data-taza": "N", "data-select2-id": "327", "text": "FIJI"},
    {"value": "FI", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "328", "text": "FINLAND"},
    {"value": "FR", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "329", "text": "FRANCE"},
    {"value": "GF", "data-eu": "N", "data-taza": "N", "data-select2-id": "330", "text": "FRENCH GUIANA"},
    {"value": "PF", "data-eu": "N", "data-taza": "N", "data-select2-id": "331", "text": "FRENCH POLYNESIA"},
    {"value": "TF", "data-eu": "N", "data-taza": "N", "data-select2-id": "332", "text": "FRENCH SOUTHERN TERRITORIES"},
    {"value": "GA", "data-eu": "N", "data-taza": "N", "data-select2-id": "333", "text": "GABON"},
    {"value": "GM", "data-eu": "N", "data-taza": "N", "data-select2-id": "334", "text": "GAMBIA"},
    {"value": "GE", "data-eu": "N", "data-taza": "N", "data-select2-id": "335", "text": "GEORGIA"},
    {"value": "DE", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "336", "text": "GERMANY"},
    {"value": "GH", "data-eu": "N", "data-taza": "N", "data-select2-id": "337", "text": "GHANA"},
    {"value": "GI", "data-eu": "N", "data-taza": "N", "data-select2-id": "338", "text": "GIBRALTAR"},
    {"value": "GR", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "339", "text": "GREECE"},
    {"value": "GL", "data-eu": "N", "data-taza": "N", "data-select2-id": "340", "text": "GREENLAND"},
    {"value": "GD", "data-eu": "N", "data-taza": "N", "data-select2-id": "341", "text": "GRENADA"},
    {"value": "GP", "data-eu": "N", "data-taza": "N", "data-select2-id": "342", "text": "GUADELOUPE"},
    {"value": "GU", "data-eu": "N", "data-taza": "N", "data-select2-id": "343", "text": "GUAM"},
    {"value": "GT", "data-eu": "N", "data-taza": "N", "data-select2-id": "344", "text": "GUATEMALA"},
    {"value": "GG", "data-eu": "N", "data-taza": "N", "data-select2-id": "345", "text": "GUERNSEY"},
    {"value": "GN", "data-eu": "N", "data-taza": "N", "data-select2-id": "346", "text": "GUINEA"},
    {"value": "GW", "data-eu": "N", "data-taza": "N", "data-select2-id": "347", "text": "GUINEA-BISSAU"},
    {"value": "GY", "data-eu": "N", "data-taza": "N", "data-select2-id": "348", "text": "GUYANA"},
    {"value": "HT", "data-eu": "N", "data-taza": "N", "data-select2-id": "349", "text": "HAITI"},
    {"value": "HM", "data-eu": "N", "data-taza": "N", "data-select2-id": "350", "text": "HEARD ISLAND AND MCDONALD ISLANDS"},
    {"value": "VA", "data-eu": "N", "data-taza": "N", "data-select2-id": "351", "text": "HOLY SEE"},
    {"value": "HN", "data-eu": "N", "data-taza": "N", "data-select2-id": "352", "text": "HONDURAS"},
    {"value": "HK", "data-eu": "N", "data-taza": "N", "data-select2-id": "353", "text": "HONG KONG"},
    {"value": "HU", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "354", "text": "HUNGARY"},
    {"value": "IS", "data-eu": "N", "data-taza": "N", "data-select2-id": "355", "text": "ICELAND"},
    {"value": "IN", "data-eu": "N", "data-taza": "N", "data-select2-id": "356", "text": "INDIA"},
    {"value": "ID", "data-eu": "N", "data-taza": "Y", "data-select2-id": "357", "text": "INDONESIA"},
    {"value": "IR", "data-eu": "N", "data-taza": "N", "data-select2-id": "358", "text": "IRAN, ISLAMIC REPUBLIC OF"},
    {"value": "IQ", "data-eu": "N", "data-taza": "N", "data-select2-id": "359", "text": "IRAQ"},
    {"value": "IE", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "360", "text": "IRELAND"},
    {"value": "IM", "data-eu": "N", "data-taza": "N", "data-select2-id": "361", "text": "ISLE OF MAN"},
    {"value": "IL", "data-eu": "N", "data-taza": "N", "data-select2-id": "362", "text": "ISRAEL"},
    {"value": "IT", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "363", "text": "ITALY"},
    {"value": "JM", "data-eu": "N", "data-taza": "N", "data-select2-id": "364", "text": "JAMAICA"},
    {"value": "JP", "data-eu": "N", "data-taza": "N", "data-select2-id": "365", "text": "JAPAN"},
    {"value": "JE", "data-eu": "N", "data-taza": "N", "data-select2-id": "366", "text": "JERSEY"},
    {"value": "JO", "data-eu": "N", "data-taza": "N", "data-select2-id": "367", "text": "JORDAN"},
    {"value": "KZ", "data-eu": "N", "data-taza": "N", "data-select2-id": "368", "text": "KAZAKHSTAN"},
    {"value": "KE", "data-eu": "N", "data-taza": "Y", "data-select2-id": "369", "text": "KENYA"},
    {"value": "KI", "data-eu": "N", "data-taza": "N", "data-select2-id": "370", "text": "KIRIBATI"},
    {"value": "KP", "data-eu": "N", "data-taza": "N", "data-select2-id": "371", "text": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"},
    {"value": "KR", "data-eu": "N", "data-taza": "N", "data-select2-id": "372", "text": "KOREA, REPUBLIC OF"},
    {"value": "KW", "data-eu": "N", "data-taza": "N", "data-select2-id": "373", "text": "KUWAIT"},
    {"value": "KG", "data-eu": "N", "data-taza": "N", "data-select2-id": "374", "text": "KYRGYZSTAN"},
    {"value": "LA", "data-eu": "N", "data-taza": "N", "data-select2-id": "375", "text": "LAO PEOPLE'S DEMOCRATIC REPUBLIC"},
    {"value": "LV", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "376", "text": "LATVIA"},
    {"value": "LB", "data-eu": "N", "data-taza": "N", "data-select2-id": "377", "text": "LEBANON"},
    {"value": "LS", "data-eu": "N", "data-taza": "N", "data-select2-id": "378", "text": "LESOTHO"},
    {"value": "LR", "data-eu": "N", "data-taza": "N", "data-select2-id": "379", "text": "LIBERIA"},
    {"value": "LY", "data-eu": "N", "data-taza": "N", "data-select2-id": "380", "text": "LIBYA"},
    {"value": "LI", "data-eu": "N", "data-taza": "N", "data-select2-id": "381", "text": "LIECHTENSTEIN"},
    {"value": "LT", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "382", "text": "LITHUANIA"},
    {"value": "LU", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "383", "text": "LUXEMBOURG"},
    {"value": "MO", "data-eu": "N", "data-taza": "N", "data-select2-id": "384", "text": "MACAO"},
    {"value": "MG", "data-eu": "N", "data-taza": "N", "data-select2-id": "385", "text": "MADAGASCAR"},
    {"value": "MW", "data-eu": "N", "data-taza": "N", "data-select2-id": "386", "text": "MALAWI"},
    {"value": "MY", "data-eu": "N", "data-taza": "N", "data-select2-id": "387", "text": "MALAYSIA"},
    {"value": "MV", "data-eu": "N", "data-taza": "N", "data-select2-id": "388", "text": "MALDIVES"},
    {"value": "ML", "data-eu": "N", "data-taza": "N", "data-select2-id": "389", "text": "MALI"},
    {"value": "MT", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "390", "text": "MALTA"},
    {"value": "MH", "data-eu": "N", "data-taza": "N", "data-select2-id": "391", "text": "MARSHALL ISLANDS"},
    {"value": "MQ", "data-eu": "N", "data-taza": "N", "data-select2-id": "392", "text": "MARTINIQUE"},
    {"value": "MR", "data-eu": "N", "data-taza": "N", "data-select2-id": "393", "text": "MAURITANIA"},
    {"value": "MU", "data-eu": "N", "data-taza": "N", "data-select2-id": "394", "text": "MAURITIUS"},
    {"value": "YT", "data-eu": "N", "data-taza": "N", "data-select2-id": "395", "text": "MAYOTTE"},
    {"value": "MX", "data-eu": "N", "data-taza": "N", "data-select2-id": "396", "text": "MEXICO"},
    {"value": "FM", "data-eu": "N", "data-taza": "N", "data-select2-id": "397", "text": "MICRONESIA, FEDERATED STATES OF"},
    {"value": "MD", "data-eu": "N", "data-taza": "N", "data-select2-id": "398", "text": "MOLDOVA, REPUBLIC OF"},
    {"value": "MC", "data-eu": "N", "data-taza": "N", "data-select2-id": "399", "text": "MONACO"},
    {"value": "MN", "data-eu": "N", "data-taza": "N", "data-select2-id": "400", "text": "MONGOLIA"},
    {"value": "ME", "data-eu": "N", "data-taza": "N", "data-select2-id": "401", "text": "MONTENEGRO"},
    {"value": "MS", "data-eu": "N", "data-taza": "N", "data-select2-id": "402", "text": "MONTSERRAT"},
    {"value": "MA", "data-eu": "N", "data-taza": "N", "data-select2-id": "403", "text": "MOROCCO"},
    {"value": "MZ", "data-eu": "N", "data-taza": "N", "data-select2-id": "404", "text": "MOZAMBIQUE"},
    {"value": "MM", "data-eu": "N", "data-taza": "N", "data-select2-id": "405", "text": "MYANMAR"},
    {"value": "NA", "data-eu": "N", "data-taza": "N", "data-select2-id": "406", "text": "NAMIBIA"},
    {"value": "NR", "data-eu": "N", "data-taza": "N", "data-select2-id": "407", "text": "NAURU"},
    {"value": "NP", "data-eu": "N", "data-taza": "N", "data-select2-id": "408", "text": "NEPAL"},
    {"value": "NL", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "409", "text": "NETHERLANDS"},
    {"value": "NC", "data-eu": "N", "data-taza": "N", "data-select2-id": "410", "text": "NEW CALEDONIA"},
    {"value": "NZ", "data-eu": "N", "data-taza": "N", "data-select2-id": "411", "text": "NEW ZEALAND"},
    {"value": "NI", "data-eu": "N", "data-taza": "N", "data-select2-id": "412", "text": "NICARAGUA"},
    {"value": "NE", "data-eu": "N", "data-taza": "N", "data-select2-id": "413", "text": "NIGER"},
    {"value": "NG", "data-eu": "N", "data-taza": "N", "data-select2-id": "414", "text": "NIGERIA"},
    {"value": "NU", "data-eu": "N", "data-taza": "N", "data-select2-id": "415", "text": "NIUE"},
    {"value": "NF", "data-eu": "N", "data-taza": "N", "data-select2-id": "416", "text": "NORFOLK ISLAND"},
    {"value": "MK", "data-eu": "N", "data-taza": "N", "data-select2-id": "417", "text": "NORTH MACEDONIA"},
    {"value": "MP", "data-eu": "N", "data-taza": "N", "data-select2-id": "418", "text": "NORTHERN MARIANA ISLANDS"},
    {"value": "NO", "data-eu": "N", "data-taza": "N", "data-select2-id": "419", "text": "NORWAY"},
    {"value": "OM", "data-eu": "N", "data-taza": "N", "data-select2-id": "420", "text": "OMAN"},
    {"value": "PK", "data-eu": "N", "data-taza": "N", "data-select2-id": "421", "text": "PAKISTAN"},
    {"value": "PW", "data-eu": "N", "data-taza": "N", "data-select2-id": "422", "text": "PALAU"},
    {"value": "PS", "data-eu": "N", "data-taza": "N", "data-select2-id": "423", "text": "PALESTINE, STATE OF"},
    {"value": "PA", "data-eu": "N", "data-taza": "N", "data-select2-id": "424", "text": "PANAMA"},
    {"value": "PG", "data-eu": "N", "data-taza": "N", "data-select2-id": "425", "text": "PAPUA NEW GUINEA"},
    {"value": "PY", "data-eu": "N", "data-taza": "N", "data-select2-id": "426", "text": "PARAGUAY"},
    {"value": "PE", "data-eu": "N", "data-taza": "N", "data-select2-id": "427", "text": "PERU"},
    {"value": "PH", "data-eu": "N", "data-taza": "Y", "data-select2-id": "428", "text": "PHILIPPINES"},
    {"value": "PN", "data-eu": "N", "data-taza": "N", "data-select2-id": "429", "text": "PITCAIRN"},
    {"value": "PL", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "430", "text": "POLAND"},
    {"value": "PT", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "431", "text": "PORTUGAL"},
    {"value": "PR", "data-eu": "N", "data-taza": "N", "data-select2-id": "432", "text": "PUERTO RICO"},
    {"value": "QA", "data-eu": "N", "data-taza": "N", "data-select2-id": "433", "text": "QATAR"},
    {"value": "RE", "data-eu": "N", "data-taza": "N", "data-select2-id": "434", "text": "RÉUNION"},
    {"value": "RO", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "435", "text": "ROMANIA"},
    {"value": "RU", "data-eu": "N", "data-taza": "N", "data-select2-id": "436", "text": "RUSSIAN FEDERATION"},
    {"value": "RW", "data-eu": "N", "data-taza": "N", "data-select2-id": "437", "text": "RWANDA"},
    {"value": "BL", "data-eu": "N", "data-taza": "N", "data-select2-id": "438", "text": "SAINT BARTHÉLEMY"},
    {"value": "SH", "data-eu": "N", "data-taza": "N", "data-select2-id": "439", "text": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"},
    {"value": "KN", "data-eu": "N", "data-taza": "N", "data-select2-id": "440", "text": "SAINT KITTS AND NEVIS"},
    {"value": "LC", "data-eu": "N", "data-taza": "N", "data-select2-id": "441", "text": "SAINT LUCIA"},
    {"value": "MF", "data-eu": "N", "data-taza": "N", "data-select2-id": "442", "text": "SAINT MARTIN (FRENCH PART)"},
    {"value": "PM", "data-eu": "N", "data-taza": "N", "data-select2-id": "443", "text": "SAINT PIERRE AND MIQUELON"},
    {"value": "VC", "data-eu": "N", "data-taza": "N", "data-select2-id": "444", "text": "SAINT VINCENT AND THE GRENADINES"},
    {"value": "WS", "data-eu": "N", "data-taza": "N", "data-select2-id": "445", "text": "SAMOA"},
    {"value": "SM", "data-eu": "N", "data-taza": "N", "data-select2-id": "446", "text": "SAN MARINO"},
    {"value": "ST", "data-eu": "N", "data-taza": "N", "data-select2-id": "447", "text": "SAO TOME AND PRINCIPE"},
    {"value": "SA", "data-eu": "N", "data-taza": "N", "data-select2-id": "448", "text": "SAUDI ARABIA"},
    {"value": "SN", "data-eu": "N", "data-taza": "N", "data-select2-id": "449", "text": "SENEGAL"},
    {"value": "RS", "data-eu": "N", "data-taza": "N", "data-select2-id": "450", "text": "SERBIA"},
    {"value": "SC", "data-eu": "N", "data-taza": "N", "data-select2-id": "451", "text": "SEYCHELLES"},
    {"value": "SL", "data-eu": "N", "data-taza": "N", "data-select2-id": "452", "text": "SIERRA LEONE"},
    {"value": "SG", "data-eu": "N", "data-taza": "Y", "data-select2-id": "453", "text": "SINGAPORE"},
    {"value": "SX", "data-eu": "N", "data-taza": "N", "data-select2-id": "454", "text": "SINT MAARTEN (DUTCH PART)"},
    {"value": "SK", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "455", "text": "SLOVAKIA"},
    {"value": "SI", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "456", "text": "SLOVENIA"},
    {"value": "SB", "data-eu": "N", "data-taza": "N", "data-select2-id": "457", "text": "SOLOMON ISLANDS"},
    {"value": "SO", "data-eu": "N", "data-taza": "N", "data-select2-id": "458", "text": "SOMALIA"},
    {"value": "ZA", "data-eu": "N", "data-taza": "N", "data-select2-id": "459", "text": "SOUTH AFRICA"},
    {"value": "GS", "data-eu": "N", "data-taza": "N", "data-select2-id": "460", "text": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"},
    {"value": "SS", "data-eu": "N", "data-taza": "N", "data-select2-id": "461", "text": "SOUTH SUDAN"},
    {"value": "ES", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "462", "text": "SPAIN"},
    {"value": "LK", "data-eu": "N", "data-taza": "N", "data-select2-id": "463", "text": "SRI LANKA"},
    {"value": "SD", "data-eu": "N", "data-taza": "N", "data-select2-id": "464", "text": "SUDAN"},
    {"value": "SR", "data-eu": "N", "data-taza": "N", "data-select2-id": "465", "text": "SURINAME"},
    {"value": "SJ", "data-eu": "N", "data-taza": "N", "data-select2-id": "466", "text": "SVALBARD AND JAN MAYEN"},
    {"value": "SE", "data-eu": "Y", "data-taza": "Y", "data-select2-id": "467", "text": "SWEDEN"},
    {"value": "CH", "data-eu": "N", "data-taza": "N", "data-select2-id": "468", "text": "SWITZERLAND"},
    {"value": "SY", "data-eu": "N", "data-taza": "N", "data-select2-id": "469", "text": "SYRIAN ARAB REPUBLIC"},
    {"value": "TW", "data-eu": "N", "data-taza": "N", "data-select2-id": "470", "text": "TAIWAN"},
    {"value": "TJ", "data-eu": "N", "data-taza": "N", "data-select2-id": "471", "text": "TAJIKISTAN"},
    {"value": "TZ", "data-eu": "N", "data-taza": "Y", "data-select2-id": "472", "text": "TANZANIA, UNITED REPUBLIC OF"},
    {"value": "TH", "data-eu": "N", "data-taza": "Y", "data-select2-id": "473", "text": "THAILAND"},
    {"value": "TL", "data-eu": "N", "data-taza": "N", "data-select2-id": "474", "text": "TIMOR-LESTE"},
    {"value": "TG", "data-eu": "N", "data-taza": "N", "data-select2-id": "475", "text": "TOGO"},
    {"value": "TK", "data-eu": "N", "data-taza": "N", "data-select2-id": "476", "text": "TOKELAU"},
    {"value": "TO", "data-eu": "N", "data-taza": "N", "data-select2-id": "477", "text": "TONGA"},
    {"value": "TT", "data-eu": "N", "data-taza": "N", "data-select2-id": "478", "text": "TRINIDAD AND TOBAGO"},
    {"value": "TN", "data-eu": "N", "data-taza": "N", "data-select2-id": "479", "text": "TUNISIA"},
    {"value": "TR", "data-eu": "N", "data-taza": "N", "data-select2-id": "480", "text": "TURKEY"},
    {"value": "TM", "data-eu": "N", "data-taza": "N", "data-select2-id": "481", "text": "TURKMENISTAN"},
    {"value": "TC", "data-eu": "N", "data-taza": "N", "data-select2-id": "482", "text": "TURKS AND CAICOS ISLANDS"},
    {"value": "TV", "data-eu": "N", "data-taza": "N", "data-select2-id": "483", "text": "TUVALU"},
    {"value": "UG", "data-eu": "N", "data-taza": "Y", "data-select2-id": "484", "text": "UGANDA"},
    {"value": "UA", "data-eu": "N", "data-taza": "N", "data-select2-id": "485", "text": "UKRAINE"},
    {"value": "AE", "data-eu": "N", "data-taza": "N", "data-select2-id": "486", "text": "UNITED ARAB EMIRATES"},
    {"value": "GB", "data-eu": "N", "data-taza": "Y", "data-select2-id": "487", "text": "UNITED KINGDOM"},
    {"value": "US", "data-eu": "N", "data-taza": "N", "data-select2-id": "252", "text": "UNITED STATES"},
    {"value": "UM", "data-eu": "N", "data-taza": "N", "data-select2-id": "488", "text": "UNITED STATES MINOR OUTLYING ISLANDS"},
    {"value": "UY", "data-eu": "N", "data-taza": "N", "data-select2-id": "489", "text": "URUGUAY"},
    {"value": "UZ", "data-eu": "N", "data-taza": "N", "data-select2-id": "490", "text": "UZBEKISTAN"},
    {"value": "VU", "data-eu": "N", "data-taza": "N", "data-select2-id": "491", "text": "VANUATU"},
    {"value": "VE", "data-eu": "N", "data-taza": "N", "data-select2-id": "492", "text": "VENEZUELA, BOLIVARIAN REPUBLIC OF"},
    {"value": "VN", "data-eu": "N", "data-taza": "Y", "data-select2-id": "493", "text": "VIET NAM"},
    {"value": "VG", "data-eu": "N", "data-taza": "N", "data-select2-id": "494", "text": "VIRGIN ISLANDS, BRITISH"},
    {"value": "VI", "data-eu": "N", "data-taza": "N", "data-select2-id": "495", "text": "VIRGIN ISLANDS, U.S."},
    {"value": "WF", "data-eu": "N", "data-taza": "N", "data-select2-id": "496", "text": "WALLIS AND FUTUNA"},
    {"value": "EH", "data-eu": "N", "data-taza": "N", "data-select2-id": "497", "text": "WESTERN SAHARA"},
    {"value": "YE", "data-eu": "N", "data-taza": "N", "data-select2-id": "498", "text": "YEMEN"},
    {"value": "ZM", "data-eu": "N", "data-taza": "N", "data-select2-id": "499", "text": "ZAMBIA"},
    {"value": "ZW", "data-eu": "N", "data-taza": "N", "data-select2-id": "500", "text": "ZIMBABWE"}
    ],
    local_methods: [],
    allow_tazapay: false,
    is_eu: false,
    selectedOption: null,
    loadingTazapay: false,
    loadingPay: false,
    loading: false,
    fieldsReady: false,
    payment_error_msg: '',
    loadingRevolut: false,
    revolut_data: {},
    revolut_error: null,
    loadingBmo: false,
    bmo_data: {},
    bmo_error: null,
  }),
  watch: {
    payment_method: {
      handler(type){
        if(this.payment_data && this.payment_data.payment_page_type == 'simple' && type == 0){
          this.getPaymentIntent();
        }
        else if(this.payment_data && this.payment_data.payment_page_type){
          if(type == 0){
            this.selectedOption = "option1";
          }
          else if(type == 1){
            this.getRevolut();
            this.selectedOption = "option2";
          }
          else if(type == 2){
            this.getPaymentIntent();
            this.selectedOption = "option3";
          }
          else if(type == 3){
            this.getBmo('type1');
            this.selectedOption = "option4";
          }
          else if(type == 4){
            this.getBmo('type2');
            this.selectedOption = "option5";
          }
          
        }
      }
    },
    express: {
      handler(bool){
        this.setExpress(bool);
      }
    },
    billing_country: {
      handler(val){
        let country = this.findCountryByValue(val);
        if(country && country['data-taza'] == "Y"){
          this.is_eu = country['data-eu'] == "Y" ? true : false;
          if(val == "AT"){
            this.local_methods = [
              "at_bank_fuer_tirol_und_vorarlberg.png",
              "at_hypo_noe_landesbank_fuer_niederoesterreich_und_wien.png",
              "xx_raiffeisenbank.png",
              "at_bank_fuer_arbeit_und_wirtschaft_und_oesterreichische_postsparkasse.png",
              "at_volksbank.png",
              "at_oberbank.png",
              "xx_sparkasse.png"
            ]
          }
          else if(val == "SE"){
            this.local_methods = [
              "xx_seb.png",
              "gb_revolut.png",
              "xx_n26.png",
            ]
          }
          else if(val == "LU"){
            this.local_methods = [
              "lu_banque_et_caisse_depargne_de_letat.png",
              "xx_ing_bank.png",
              "lu_banque_internationale_a_luxembourg.png",
              "lu_banque_raiffeisen_luxemburg.png"
            ]
          }
          else if(val == "ES"){
            this.local_methods = [
              "es_caja_rural_del_sur.png",
              "es_kuxtabank.png",
              "es_caixabank.png",
              "es_unicaja_banco.png",
              "es_bbva.png",
              "es_ibercaja.png",
              "es_banco_de_sabadell.png",
            ]
          }
          else if(val == "CY"){
            this.local_methods = [
              "cy_hellenic.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "PL"){
            this.local_methods = [
              "pl_bank_millennium.png",
              "pl_pekao_bank.png",
              "pl_mbank.png",
              "pl_bos_bank_ochrony_srodowiska.png",
              "pl_pko_bank.png",
              "pl_alior_bank.png",
              "xx_santander_bank.png",
            ]
          }
          else if(val == "RO"){
            this.local_methods = [
              "ro_bancatransilvania.png",
              "xx_raiffeisenbank.png",
              "xx_ing_bank.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "SL"){
            this.local_methods = [
              "si_banka_intesanpaolo.png",
              "gb_revolut.png",
              "xx_n26.png",
            ]
          }
          else if(val == "IE"){
            this.local_methods = [
              "ie_avantcard.png",
              "gb_allied_irish_bank.png",
              "gb_bank_of_ireland_uk.png",
              "ie_elavon_financial_services.png",
              "ie_an_post_money.png",
              "gb_revolut.png",
              "xx_n26.png",
            ]
          }
          else if(val == "GR"){
            this.local_methods = [
              "gb_revolut.png",
              "xx_n26.png",
            ]
          }
          else if(val == "BG"){
            this.local_methods = [
              "gb_revolut.png",
            ]
          }
          else if(val == "PT"){
            this.local_methods = [
              "pt_caixa_geral_de_depositos.png",
              "pt_banco_activobank.png",
              "pt_novo_banco.png",
              "pt_bankinter.png",
              "xx_santander_bank.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "EE"){
            this.local_methods = [
              "ee_coop_pank.png",
              "xx_luminor_bank.png",
              "ee_as_tbb_pank.png",
              "ee_lhv_pank.png",
              "xx_swedbank.png",
              "gb_revolut.png",
              "xx_n26.png",
            ]
          }
          else if(val == "MT"){
            this.local_methods = [
              "gb_revolut.png",
            ]
          }
          else if(val == "HR"){
            this.local_methods = [
              "xx_otp.png",
              "xx_unicredit.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "FI"){
            this.local_methods = [
              "fi_oma_saastopankki_oyj.png",
              "fi_op_corporate_bank.png",
              "fi_saastopankki.png",
              "fi_sbanken.png",
              "xx_nordea_bank.png",
              "fi_bonum_bank_pop.png",
              "fi_alandsbanken.png",
            ]
          }
          else if(val == "DE"){
            this.local_methods = [
              "xx_raiffeisenbank.png",
              "xx_deutschebank.png",
              "de_postbank.png",
              "xx_sparkasse.png",
              "de_commerzbank.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "BE"){
            this.local_methods = [
              "be_bnp_paribas_fortis.png",
              "be_argenta.png",
              "be_axa_bank.png",
              "be_kbc_bank.png",
              "xx_ing_bank.png",
              "be_europabank.png",
              "be_belfius_bank.png",
            ]
          }
          else if(val == "IT"){
            this.local_methods = [
              "it_bcc.png",
              "it_banco_posta.png",
              "it_bper_banca.png",
              "it_banco_bpm.png",
              "xx_unicredit.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "LT"){
            this.local_methods = [
              "xx_luminor_bank.png",
              "xx_seb.png",
              "lt_uab_medicinos_bankas.png",
              "xx_swedbank.png",
              "xx_citadele.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "NL"){
            this.local_methods = [
              "nl_knab.png",
              "nl_sns_bank.png",
              "nl_regiobank.png",
              "xx_ing_bank.png",
              "gb_revolut.png",
              "nl_asn_bank.png",
            ]
          }
          else if(val == "HU"){
            this.local_methods = [
              "hu_cib_bankcorp.png",
              "xx_otp.png",
              "xx_raiffeisenbank.png",
              "hu_kandh_bank.png",
              "hu_magnet_bank.png",
              "hu_budapest_bank.png",
            ]
          }
          else if(val == "FR"){
            this.local_methods = [
              "fr_la_banque_postale.png",
              "fr_cic_banques.png",
              "fr_societe_generale.png",
              "fr_credit_mutuel.png",
              "gb_revolut.png",
              "xx_n26.png",
            ]
          }
          else if(val == "CZ"){
            this.local_methods = [
              "xx_raiffeisenbank.png",
              "xx_ing_bank.png",
              "cz_moneta_money_bank.png",
              "cz_csob.png",
              "xx_sparkasse.png",
              "xx_unicredit.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "SK"){
            this.local_methods = [
              "sk_tatrabanka.png",
              "gb_revolut.png",
            ]
          }
          else if(val == "LV"){
            this.local_methods = [
              "lv_industra_bank.png",
              "lv_blueorange_bank.png",
              "lv_luminor_bank.png",
              "lv_seb.png",
              "lv_swedbank.png",
              "xx_citadele.png",
            ]
          }
          // NON-EU //
          else if(val == "AU"){
            this.local_methods = [
              "payid.png",
            ]
          }
          else if(val == "BR"){
            this.local_methods = [
              "pix.png",
            ]
          }
          else if(val == "CO"){
            this.local_methods = [
              "dlocal.png",
            ]
          }
          else if(val == "EG"){
            this.local_methods = [
              "mobile-money.png",
              "mobile-banking.png",
            ]
          }
          else if(val == "GB"){
            this.local_methods = [
              "other-banks.png",
              "gb_allied_irish_bank.png",
              "gb_bank_of_scotland.png",
              "gb_national_westminster_bank.png",
              "gb_royal_bank_of_scotland.png",
            ]
          }
          else if(val == "GH"){
            this.local_methods = [
              "mobile-money.png",
              "mobile-banking.png",
              "gb_bank_of_scotland.png",
              "gb_national_westminster_bank.png",
              "gb_royal_bank_of_scotland.png",
            ]
          }
          else if(val == "ID"){
            this.local_methods = [
              "qris.svg",
              "bank-transfer.svg",
              "ShopeePay.svg",
              "doku.svg",
              "dana.svg",
              "link-aja.svg"
            ]
          }
          else if(val == "IE"){
            this.local_methods = [
              "bank-transfer.svg",
            ]
          }
          else if(val == "KE"){
            this.local_methods = [
              "mobile-money.png",
            ]
          }
          else if(val == "KR"){
            this.local_methods = [
              "bank-transfer.svg",
            ]
          }
          else if(val == "MX"){
            this.local_methods = [
              "SPEI.svg",
            ]
          }
          else if(val == "NZ"){
            this.local_methods = [
              "finmo.png",
            ]
          }
          else if(val == "PH"){
            this.local_methods = [
              "bank-transfer.svg",
              "ShopeePay.svg",
              "GrabPay.svg",
              "Paymaya.svg",
            ]
          }
          else if(val == "SG"){
            this.local_methods = [
              "SGpaynow.svg",
              "bank-transfer.svg",
            ]
          }
          else if(val == "ZA"){
            this.local_methods = [
              "mobile-banking.png",
            ]
          }
          else if(val == "TZ"){
            this.local_methods = [
              "mobile-money.png",
              "mobile-banking.png",
            ]
          }
          else if(val == "TH"){
            this.local_methods = [
              "PromptPay.png",
              "BAY.png",
              "KBANK.png",
              "SCB.png",
              "BBL.png",
              "KTB.png",
            ]
          }
          else if(val == "UG"){
            this.local_methods = [
              "mobile-money.png",
              "mobile-banking.png"
            ]
          }
          else if(val == "VN"){
            this.local_methods = [
              "bank-transfer.png",
              "momo.png",
              "ShopeePay.svg",
              "appota.png",
            ]
          }
          this.allow_tazapay = true;
        }
        else{
          this.allow_tazapay = false;
        }
      }
    }
  },
  computed: {
    currentSize() {
      const breakpoint = this.$vuetify.breakpoint;
      if (breakpoint.xs) return 1;
      if (breakpoint.sm) return 2;
      if (breakpoint.md) return 3;
      if (breakpoint.lg) return 4;
      if (breakpoint.xl) return 5;
      return 0;
    }
  },
  methods: {
    initialize(){
        this.loading = true;
        let token = this.$store.state.token;
        // let token = "94-NG-73288732";
        this.axiosCall(this.apiUrl2 + '/payments/?application_token=' + token + '&action=check_payment_status', 'GET').then((res) => {
          if(res.status == 200){
            this.loading = false
            if(res.data.type && res.data.type == "error"){
              console.log(res.data.message || res.data.messgae);
            }
            else{
              let details = res.data.payment_details;
              let payee = res.data.payee;
              if(details.paid){
                this.$router.push("/" + token + '/additional-info/');
                this.$router.go();
              }
              else{
                this.express = details.express;
                this.billing_country = payee.nationality;
                window._gs('event', 'Payment Page');
              }
            }
          }
          else{
            console.log("Something went wrong!");
          }
          })
      },
    getPaymentSettings(){
      this.loading = true;
        let token = this.$store.state.token;
        this.axiosCall(this.apiUrl2 + '/payments/?application_token=' + token + '&action=get_payment_settings', 'GET').then((res) => {
          if(res.status == 200){
            if(res.data.type && res.data.type == "error"){
              console.log(res.data.message || res.data.messgae);
            }
            else{
              this.payment_data = res.data;
              // let pm = res.data.payment_methods;
              // this.payment_data.payment_methods = [...pm, "bmo"];
              // this.payment_data.payment_page_type = 'simple_plus_local'
              if(this.$store.state.revolut_error && this.payment_data && this.payment_data.payment_methods && this.payment_data.payment_methods.length > 0){
                // let valueToRemove = 'revolut';
                // let index = this.payment_data.payment_methods.indexOf(valueToRemove);
                // if (index !== -1) {
                //   this.payment_data.payment_methods.splice(index, 1);
                // }
                let error = this.$store.state.revolut_error 
                // this.payment_error_msg = error.replace("RevolutCheckout: ", "");
                this.revolut_error = error;
                // this.getRevolut();
                // this.payment_method = 1;
                // this.selectedOption = "option2";
                setTimeout(() => {
                  this.$store.dispatch("setRevolutError", null);
                }, 100);
              }
              // else if(this.$store.state.bmo_error && this.payment_data && this.payment_data.payment_methods && this.payment_data.payment_methods.length > 0){
              //   // let valueToRemove = 'revolut';
              //   // let index = this.payment_data.payment_methods.indexOf(valueToRemove);
              //   // if (index !== -1) {
              //   //   this.payment_data.payment_methods.splice(index, 1);
              //   // }
              //   let error = this.$store.state.bmo_error 
              //   // this.payment_error_msg = error.replace("RevolutCheckout: ", "");
              //   this.bmo_error = error;
              //   this.getBmo();
              //   this.payment_method = 3;
              //   this.selectedOption = "option4";
              //   this.$store.dispatch("setBmoError", null);
              // }
              this.initialize()
            }
          }
          else{
            console.log("Something went wrong!");
          }
          })
    },
    checkPaymentAvailability(type){
      let payment_methods = this.payment_data.payment_methods || null;
      if(payment_methods && payment_methods.includes(type)){
        return true;
      }
      else{
        return false;
      }
    },
    getPaymentIntent(){
        this.loading = true;
        let token = this.$store.state.token;
        let type = this.payment_data.payment_page_type || '';
        this.axiosCall(this.apiUrl2 + '/payments/?application_token=' + token + '&payment_method=stripe&source=' + this.G_DOMAIN + '&payment_type=' + type + '&action=initiate_payment', 'GET').then((res) => {
          if(res.status == 200){
            this.loading = false
            if(res.data.type && res.data.type == "error"){
              console.log(res.data.message || res.data.messgae);
            }
            else{
              this.stripe_data = res.data;
              this.elementsOptions.clientSecret = this.stripe_data.payment_details.payment_intent_secret;
              this.public_key = this.stripe_data.payment_details.public_key;
              this.confirmParams.return_url = this.apiUrl1 + '/' + token + '/additional-info/';
              setTimeout(() => {
                this.checkIfFieldsReady();
              }, 1100);
            }
          }
          else{
            console.log("Something went wrong!");
          }
          })
      },
      handleError(error) {
      this.payment_error_msg = error.message || 'An error occurred';
      this.loadingPay = false
      this.postError(this.$store.state.token, this.stripe_data.payment_details.payment_intent, this.G_DOMAIN, error.type, error.message, JSON.stringify(error));
      this.stripe_data = {};
      this.getPaymentIntent();
    },
    pay () {
        this.payment_error_msg = '';
        this.loadingPay = true
        
        try {
          this.$refs.paymentRef.submit()
        } catch (error) {
          console.log(error)
          this.loadingPay = false
        }
    },
    tazapay(){
      if(this.billing_country){
      let country = this.findCountryByValue(this.billing_country);
      let token = this.$store.state.token;
      this.loadingTazapay = true;
      window._gs('event', 'Selected Payment by Local Payment Method (' + country.text + ')');
      const tazapay = window.open("https://checkout.upmax-soft.us/payment.php?type=vg&app_token=" + token + "&billing_country=" + this.billing_country, "_self");
      if(tazapay){
        this.loadingTazapay = false;
      }
      }
    },
    setExpress(bool){
        let token = this.$store.state.token;
        let express = bool ? true : false;
        this.axiosCall(this.apiUrl2 + '/payments/?application_token=' + token + '&action=upgrade_application&express=' + express, 'GET').then((res) => {
          if(res){
            console.log("OK")
          }
          else{
            console.log("Something went wrong!");
          }
          })
      },
    findCountryByValue(value) {
        return value ? this.billing_countries.find(country => country.value === value) : null;
    },
    checkIfFieldsReady() {
        const elements = document.querySelector('.StripeElement');
        if (elements) {
          this.fieldsReady = true;
        }
    },
    postError(token, payment_intent, source, error_type, error_message, error_data){
      if(token, payment_intent, source, error_type, error_message, error_data){
      this.axiosCall(this.apiUrl2 + '/payments/?application_token=' + token + '&payment_intent=' + payment_intent + '&action=log_error&source=' + source + '&error_type=' + error_type + '&error_message=' + error_message + '&error_data=' + error_data, 'GET').then((res) => {
          if(res){
            console.log("Error Sent")
          }
          else{
            console.log("Something went wrong!");
          }
          })
      }
      else{
        console.log("The Error Data was not sufficient!");
      }
    },
    getRevolut(){
        this.loadingRevolut = true;
        let token = this.$store.state.token;
        let type = this.payment_data.payment_page_type || '';
        this.axiosCall(this.apiUrl2 + '/payments/?application_token=' + token + '&payment_method=revolut&source=' + this.G_DOMAIN + '&payment_type=' + type + '&action=initiate_payment', 'GET').then((res) => {
          if(res.status == 200){
            this.loadingRevolut = false
            if(res.data.type && res.data.type == "error"){
              console.log(res.data.message || res.data.messgae);
            }
            else{
              this.revolut_data = res.data;
            }
          }
          else{
            console.log("Something went wrong!");
          }
          })
      },
      revolut(){
        if(this.revolut_data && this.revolut_data.payment_details && this.revolut_data.payment_details.checkout_url){
          this.loadingRevolut = true;
          window._gs('event', 'Selected Payment by Revolut Payment Method');
          const revolut = window.open(this.revolut_data.payment_details.checkout_url, "_self");
          if(revolut){
            this.loadingTazapay = false;
          }
          else{
            console.log("Invalid checkout URL!");
          }
        }
      },
      getBmo(card_type){
        this.loadingBmo = true;
        let token = this.$store.state.token;
        let type = this.payment_data.payment_page_type || '';
        this.axiosCall(this.apiUrl2 + '/payments/?application_token=' + token + '&payment_method=bmo&source=' + this.G_DOMAIN + '&payment_type=' + type + '&action=initiate_payment&card_type=' + card_type, 'GET').then((res) => {
          if(res.status == 200){
            this.loadingBmo = false
            if(res.data.type && res.data.type == "error"){
              console.log(res.data.message || res.data.messgae);
            }
            else{
              this.bmo_data = res.data;
            }
          }
          else{
            console.log("Something went wrong!");
          }
          })
      },
      bmo(){
        if(this.bmo_data && this.bmo_data.payment_details && this.bmo_data.payment_details.checkout_url){
          this.loadingBmo = true;
          window._gs('event', 'Selected Payment by Revolut Payment Method');
          const bmo = window.open(this.bmo_data.payment_details.checkout_url, "_self");
          if(bmo){
            this.loadingTazapay = false;
          }
          else{
            console.log("Invalid checkout URL!");
          }
        }
      }
  },
  created(){
    this.getPaymentSettings();
  },
  mounted() {
    // if(this.$store.state.revolut_error){
    //   let error = this.$store.state.revolut_error 
    //   this.revolut_error = error.replace("RevolutCheckout: ", "");
    //   this.getRevolut();
    //   this.payment_method = 1;
    //   this.selectedOption = "option2";
    //   this.$store.dispatch("setRevolutError", null);
    // }
  },
};
</script>

<style scoped>
.outer-content {
  background-color: #ffffff !important;
}
.bg-content {
  /* background: url("../../assets/img/background.png"); */
   position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.inner-content{
    background-color: #ffffff !important;
}
.content{
    margin: 60px 0;
    margin-bottom: 1000px !important;
}
.content section {
    margin: 0 10%;
}
.columns{
    background: white;
    border-radius: 10px;
    border: 1px solid #cccccc;
    padding: 30px;
}
.payment-alert{
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  font-weight: bolder;
}
.payment-service-type {
  background-color: #f8f9fa !important;
  border: 1px solid #dee2e6 !important;
  border-radius: .25rem !important;
}
.payment-desc{
  font-size: 15px;
  font-weight: 380;
  color: #000;
}
.payment-add-ons {
  color: #000;
}
.express-style {
  color: #00A34E;
}
.lm-style {
  width: auto;
  height: 30px;
}
.cc-style {
  width: 50px;
}
.service-details {
  font-size: 15px;
  font-weight: 420;
  color: #000;
}
.bc-style {
  background: #e2e8f0;
  padding: 10px !important;
}
.so-style {
  background: #3c3b6b;
  padding: 10px !important;
  color: white;
}
.simple-style .theme--light.v-sheet--outlined {
    border: thin solid #00b356 !important;
    /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important; */
    box-shadow: rgba(0, 146, 70, 0.12) 0px 2px 4px 0px, rgba(0, 146, 70, 0.32) 0px 2px 16px 0px !important;
    /* box-shadow: rgba(0, 146, 70, 0.1) 0px 4px 16px, rgba(0, 146, 70, 0.05) 0px 8px 32px !important; */
    /* box-shadow: rgba(0, 146, 70, 0.2) 0px 18px 50px -10px; */
}
.unclickable {
  pointer-events: none;
}
@media only screen and (max-width: 600px) {
    .inner-content{
        padding: 0 1%;
    }
    .content{
        margin: 50px 0;
    }
    .content section {
    margin: 0;
    }
}
@media only screen and (max-width: 1260px) {
    .cc-style{
        width: 40px !important;
    }
    .lm-style {
      height: 25px;
    }
}
@media only screen and (max-width: 650px) {
    .cc-style{
        width: 30px !important;
    }
    .lm-style {
      height: 20px;
    }
}
/* @media only screen and (max-width: 490px) {
    .cc-style{
        width: 20px !important;
    }
    .lm-style {
      height: 15px;
    }
} */
</style>
